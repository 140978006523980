import React from 'react'
import About from '../components/About'

class IndexPage extends React.Component {
  // constructor() {
  //   console.log('con' + window.scrollY)
  //   super()
  //
  //   const intervalId = setInterval(() => {console.log('int' + window.scrollY)}, 20)
  //  // store intervalId in the state so it can be accessed later:
  //    this.state = {intervalId: intervalId}
  // }
  // componentDidMount() {
  //   console.log(window.scrollY)
  // }
  //
  // componentWillUnmount() {
  //   console.log('un' + window.scrollY)
  //   clearInterval(this.state.intervalId);
  // }
  //
  // componentDidUpdate() {
  //   console.log('upd' + window.scrollY)
  // }
  //
  // componentWillReceiveProps() {
  //   console.log('re' + window.scrollY)
  // }
  //
  // componentWillReceiveProps() {
  //   console.log('re2' + window.scrollY)
  // }

  render() {
    return (
      <About/>
    )
  }
}

export default IndexPage
